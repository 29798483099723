import React, { useContext, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUpRightFromSquare,
  faFileExcel,
} from '@fortawesome/free-solid-svg-icons'

// Components
import Modal from '../../organisms/Modal'

// Context
import TablesContext from '../../../context/Tables/Tables.context'
import RegisterContext from '../../../context/Register/Register.context'

// Style
import {
  tab,
  tabCell,
  tabIcon,
  tabLink,
  tabTitle,
  tables,
  excel,
  wrapper,
  tabImage,
  tabHeader,
  tabSeries,
} from './style.module.scss'

export type RegisterTabs = 'Author' | 'Publisher' | 'Series' | 'Chapters'

const Tables: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<RegisterTabs>('Series')

  const {
    types,
    cities,
    genres,
    demAges,
    auxTeam,
    statuses,
    demSexes,
    auxGenres,
    ageRanges,
    countries,
    departments,
    distributions,
    updateTypes,
    updateCities,
    updateGenres,
    updateDemAges,
    updateAuxTeam,
    updateAuxGenre,
    updateDemSexes,
    updateStatuses,
    updateAgeRanges,
    updateCountries,
    updateDepartments,
    updateDistributions,
  } = useContext(RegisterContext)

  const {
    series,
    authors,
    publishers,
    publications,
    updateSeries,
    updateAuthors,
    updatePublishers,
    updatePublications,
  } = useContext(TablesContext)

  useEffect(() => {
    if (types?.length === 0) updateTypes()
    if (cities?.length === 0) updateCities()
    if (genres?.length === 0) updateGenres()
    if (demAges?.length === 0) updateDemAges()
    if (demSexes?.length === 0) updateDemSexes()
    if (statuses?.length === 0) updateStatuses()
    if (countries?.length === 0) updateCountries()
    if (ageRanges?.length === 0) updateAgeRanges()
    if (departments?.length === 0) updateDepartments()
    if (distributions?.length === 0) updateDistributions()
    if (auxTeam?.length === 0) updateAuxTeam()
    if (auxGenres?.length === 0) updateAuxGenre()
    if (series?.length === 0) updateSeries()
    if (authors?.length === 0) updateAuthors()
    if (publishers?.length === 0) updatePublishers()
    if (publications?.length === 0) updatePublications()
  }, [])

  return (
    <div className={tables}>
      <Modal />
      <div className={wrapper}>
        <div className={[tabSeries, tab].join(' ')}>
          <div className={tabTitle}>
            Series{' '}
            <div className={excel}>
              Exportar a Excel
              <FontAwesomeIcon className={tabIcon} icon={faFileExcel} />
            </div>
          </div>
          <div className={tabHeader}>Portada</div>
          <div className={tabHeader}>Nombre</div>
          <div className={tabHeader}>Estado</div>
          <div className={tabHeader}>Descripción</div>
          <div className={tabHeader}>País</div>
          <div className={tabHeader}>Capítulos</div>
          <div className={tabHeader}>Vista previa</div>
          <div className={tabHeader}>Lectura</div>
          <div className={tabHeader}>Venta</div>
          <div className={tabHeader}>Patreon</div>
          <div className={tabHeader}>Vaki</div>
          {series.map((s) => (
            <>
              <div className={tabCell}>
                <img className={tabImage} src={s.cover ?? ''} />
              </div>
              <div className={tabCell}>{s.title}</div>
              <div className={tabCell}>
                {statuses.find((x) => x.id === s.status)?.name ?? 'Desconosido'}
              </div>
              <div className={tabCell}>{s.description}</div>
              <div className={tabCell}>
                {countries.find((x) => x.id === s.country)?.code ?? 'CO'}
              </div>
              <div className={tabCell}>
                {publications.filter((x) => x.series === s.id).length}
              </div>
              <div className={[tabCell, tabLink].join(' ')}>
                {s.preview ? (
                  <a target="_blank" href={s.preview}>
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  </a>
                ) : (
                  '-'
                )}
              </div>
              <div className={[tabCell, tabLink].join(' ')}>
                {s.link ? (
                  <a target="_blank" href={s.link}>
                    {' '}
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  </a>
                ) : (
                  '-'
                )}
              </div>
              <div className={[tabCell, tabLink].join(' ')}>
                {s.shop ? (
                  <a target="_blank" href={s.shop}>
                    {' '}
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  </a>
                ) : (
                  '-'
                )}
              </div>
              <div className={[tabCell, tabLink].join(' ')}>
                {s.patreon ? (
                  <a target="_blank" href={s.patreon}>
                    {' '}
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  </a>
                ) : (
                  '-'
                )}
              </div>
              <div className={[tabCell, tabLink].join(' ')}>
                {s.vaki ? (
                  <a target="_blank" href={s.vaki}>
                    {' '}
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  </a>
                ) : (
                  '-'
                )}
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Tables
