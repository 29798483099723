// extracted by mini-css-extract-plugin
export var excel = "style-module--excel--22e15";
export var tab = "style-module--tab--c5975";
export var tabCell = "style-module--tab-cell--5ca2f";
export var tabHeader = "style-module--tab-header--18ce5";
export var tabIcon = "style-module--tab-icon--5308a";
export var tabImage = "style-module--tab-image--0188d";
export var tabLink = "style-module--tab-link--ad597";
export var tabSeries = "style-module--tab-series--b1402";
export var tabTitle = "style-module--tab-title--9d6f6";
export var tables = "style-module--tables--1c71a";
export var wrapper = "style-module--wrapper--75a73";