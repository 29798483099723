import React, { useContext } from 'react'
import ModalContext from '../../../context/Modal/Modal.context'

// Styles
import { wrapper, modalInner } from './style.module.scss'

const Modal: React.FC = () => {
  const { isOpen, modal, closeModal, unclosable } = useContext(ModalContext)
  return (
    <>
      {isOpen && (
        <div
          className={wrapper}
          onClick={() => {
            if (!unclosable) closeModal()
          }}
        >
          <div className={modalInner}>{modal}</div>
        </div>
      )}
    </>
  )
}

export default Modal
